.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0;
    box-sizing: border-box;
    width: 100%;
}

.paragraph {
    max-width: 661px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 142.5%;
    text-align: center;
    color: #F8F8F8;
    margin: 0;
    padding: 0;
}

.paragraph:nth-of-type(2n) {
    margin-top: 30px;
}

@media (max-width: 1280px) {
    .about {
        padding: 80px 0;
    }
    .paragraph {
        font-size: 16px;
        line-height: 135.5%;
        max-width: 459px;
    }
    .paragraph:nth-of-type(2n) {
        margin-top: 20px;
    }
}

@media (max-width: 550px) {
    .about {
        padding: 60px 0;
        background-color: #070707;
    }
    .paragraph {
        font-size: 13px;
        line-height: 129%;
        max-width: 345px;
    }
    .paragraph:nth-of-type(2n) {
        margin-top: 15px;
    }
}
