.thanks {
    width: 730px;
    height: 512px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(15, 15, 15, 0.8);
    border-radius: 10px;
    position: relative;
    padding: 152px 108px;
    box-sizing: border-box;
    position: relative;
}

.title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    color: #F8F8F8;
    margin: 0;
    margin-bottom: 35px;
}

.subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #F8F8F8;
    max-width: 503px;
    margin: 0;
    margin-bottom: 40px;
}

.text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 142.5%;
    text-align: center;
    color: #F8F8F8;
    margin: 0;
}

.close {
    background-image: url(../../images/icons/cross.png);
    width: 24px;
    height: 24px;
    border: none;
    padding: 0;
    margin: 0;
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;
}

@media (max-width: 1280px) {
    .thanks {
        width: 344px;
        height: 418px;
        padding: 104px 30px;
        box-sizing: border-box;
    }
    .title {
        margin-bottom: 25px;
        font-size: 20px;
        line-height: 24px;
    }
    
    .subtitle {
        margin-bottom: 30px;
        font-size: 16px;
        line-height: 19px;
    }
    
    .text {
        font-size: 13px;
        line-height: 135.5%;
    }
}