.footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 200px 0 92px;
}

.border {
    border-top: 1px solid #8F8F8F;
    border-bottom: 1px solid #8F8F8F;
    width: 100%;
    margin-bottom: 100px;
}

.social {
    display: flex;
    list-style-type: none;
    max-width: 142px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin: 0;
}

.icon {
    width: 24px;
    height: 24px;
}

.copyright {
    margin: 0;
    padding: 0;
    margin-top: 55px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    color: #8F8F8F;
}

@media (max-width: 1280px) {
    .footer {
        padding: 80px 0 65px;
    }
    .border {
        margin-bottom: 55px;
    }
    .copyright {
        font-size: 16px;
        line-height: 20px;
    }
}

@media (max-width: 550px) {
    .footer {
        padding: 65px 0 65px;
    }
    .border {
        margin-bottom: 55px;
    }
    .social {
        margin-top: 55px;
    }
    .copyright {
        margin-top: 40px;
        font-size: 14px;
        line-height: 17px;
    }
}