.secret {
    padding: 100px 0;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}

.paragraph {
    margin: 0;
    padding: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #F8F8F8;
    max-width: 522px;
}

@media (max-width: 1280px) {
    .paragraph {
        font-size: 16px;
        line-height: 19px;
        max-width: 392px;
    }
}

@media (max-width: 550px) {
    .paragraph {
        font-size: 13px;
        line-height: 16px;
        max-width: 301px;
    }
    .secret {
        padding: 65px 0 0;
    }
}