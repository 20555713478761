@import url('../src/vendor/normalize.css');
@import url('../src/vendor/fonts/fonts.css');

body {
  background-color: rgba(14, 14, 14, 1);
}

.page {
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(14, 14, 14, 1);
}
