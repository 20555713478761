.button {
    background: rgba(0, 0, 0, 0.7);
    border: 3px solid #F8F8F8;
    border-radius: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 29px;
    color: #F8F8F8;
    text-decoration: none;
    padding: 13px 47px;
    box-sizing: border-box;
    transition: all ease 0.2s;
    cursor: pointer;
}

.button:hover {
    background-color: rgba(53, 53, 53, 1);
}

@media (max-width: 1280px) {
    .button {
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        padding: 16px 28px;
        border: 2px solid #F8F8F8;
    }
}