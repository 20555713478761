.form {
    width: 730px;
    height: 512px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(15, 15, 15, 0.8);
    border-radius: 10px;
    position: relative;
    padding-top: 95px;
    box-sizing: border-box;
}

.inputContainer {
    width: 100%;
    border: none;
    display: flex;
    flex-direction: column;
    max-width: 446px;
    margin: 0;
    padding: 0;
}

.optionsContainer {
    border: none;
    display: flex;
    max-width: 446px;
    width: 100%;
    margin: 0;
    padding: 0;
    justify-content: space-between;
    margin-bottom: 69px;
    align-items: center;
}

.icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.submitContainer {
    border: none;
    display: flex;
    margin: 0 auto;
    padding: 0;
}

.textLabel {
    position: relative;
}

.textInput {
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    border-bottom: 1px solid #F8F8F8;
    outline: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    color: #ffffff;
    margin-bottom: 55px;
    padding-bottom: 5px;
}

.optionsInput{
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #F8F8F8;
    list-style-type: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
}

.submitButton {
    background: rgba(15, 15, 15, 0.8);
    border: 3px solid #F8F8F8;
    border-radius: 10px;
    padding: 13px 150px;
    box-sizing: border-box;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 29px;
    color: #F8F8F8;
    cursor: pointer;
    transition: all ease 0.2s;
}

.submitButton:disabled {
    background: rgba(24, 24, 24, 1);
    color: #ABABAB;
    cursor: unset;
}

.submitButton:not([disabled]):hover {
    background-color: rgba(53, 53, 53, 1);
}

.guests {
    display: flex;
}

.text{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #F8F8F8;
    margin: 0;
    padding: 0;
    margin-left: 7px;
}

.optionsBox {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #070707;
    border: none;
    outline: none;
    background: #7C7C7C;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow: hidden;
}

.inputLabel {
    position: relative;
    display: flex;
    align-items: center;
}

.hiddenInput {
    display: none;
}

.error {
    font-family: 'Inter';
    font-size: 16px;
    line-height: 16px;
    color: #fc0606;
    font-style: normal;
    font-weight: 400;
    position: absolute;
    left: 0;
    top: 40px;
}

.errorDate {
    font-family: 'Inter';
    font-size: 16px;
    line-height: 16px;
    color: #fc0606;
    font-style: normal;
    font-weight: 400;
    position: absolute;
    left: 0;
    top: 28px;
}

.dropDown {
    position: relative;
}

.dropDownButton {
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    background-color: rgba(0, 0, 0, 0);
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #F8F8F8;
    display: flex;
}

.dropDownList {
    list-style-type: none;
    margin: 0;
    padding: 20px 0 0;
    box-sizing: border-box;
    position: absolute;
    top: 25px;
    background-color: rgba(124, 124, 124, 1);
    left: -40px;
    z-index: 100;
    border-radius: 13px;
    height: 200px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: black white;
    scrollbar-width: 4px;
    scroll-behavior: smooth;
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dropDownItem {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: rgba(7, 7, 7, 1);
    cursor: pointer;
    width: 100%;
    text-align: center;
}

.dropDownItem:hover {
    color: rgba(248, 248, 248, 1);
}

.dropDownItem:nth-of-type(n+1) {
    margin-bottom: 20px;
}

@media (max-width: 1280px) {
    .form {
        padding-top: 50px;
        width: 598px;
        height: 383px;
    }
    .textInput {
        margin-bottom: 50px;
        padding-bottom: 5px;
        font-size: 20px;
        line-height: 24px;
    }
    .optionsContainer {
        margin-bottom: 50px;
    }
    .error {
        font-size: 13px;
        line-height: 15px;
        top: 35px;
    }
    .errorDate{
        font-size: 13px;
        line-height: 15px;
        top: 30px;
    }
}

@media (max-width: 550px) {
    .form {
        padding-top: 30px;
        width: 344px;
        height: 418px;
    }
    .textInput {
        margin-bottom: 50px;
        padding-bottom: 5px;
        font-size: 18px;
        line-height: 22px;
    }
    .optionsInput {
        font-size: 18px;
        line-height: 22px;
    }
    .inputContainer {
        max-width: 283px;
    }
    .optionsContainer {
        max-width: 283px;
        margin-bottom: 50px;
    }
    .submitButton {
        border: 2px solid #F8F8F8;
        padding: 15px 74px;
        font-size: 16px;
        line-height: 25px;
    }
    .optionsContainer {
        max-width: 283px;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .error {
        font-size: 12px;
        line-height: 13px;
        top: 35px;
    }
    .errorDate{
        font-size: 12px;
        line-height: 13px;
        top: 30px;
    }
    .guests{
        margin-top: 48px;
    }
}