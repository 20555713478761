.header {
    width: 100%;
    min-height: 889px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(../../images/headerbg.jpg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.title {
    font-family: 'BarlowCondensed';
    font-style: normal;
    font-weight: 400;
    font-size: 80px;
    line-height: 88px;
    text-align: center;
    color: #F8F8F8;
    margin: 0 auto 27px;
    padding: 0;
}

.social {
    display: flex;
    list-style-type: none;
    max-width: 142px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 50px;
    padding: 0;
    margin: 0;
}

.icon {
    width: 24px;
    height: 24px;
}

@media (max-width: 1439px) {
    .header {
        min-height: 61.74vw;
    }
}

@media (max-width: 1280px) {
    .title {
        font-size: 60px;
        line-height: 66px;
    }
    .social {
        top: 30px;
    }
}

@media (max-width: 550px) {
    .title {
        max-width: 161px;
        font-size: 30px;
        line-height: 55px;

    }
    .header {
        min-height: 505px;
        background-size: cover;
    }
    .social {
        top: 25px;
    }
}