.drinks {
    background-color: rgba(32, 32, 32, 1);
    display: flex;
    width: 100%;
    align-content: center;
    justify-content: center;
    padding: 55px 0;
}

.image {
    max-width: 351px;
    max-height: 444px;
    margin-right: 45px;
    padding: 0;
}

.text {
    display: flex;
    flex-direction: column;
}

.paragraph {
    display: inline-block;
    margin: 0;
    padding: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 142.5%;
    color: #F8F8F8;
    max-width: 404px;
}

.paragraph:nth-of-type(2n) {
    margin-top: 30px;
}

@media (max-width: 1280px) {
    .drinks {
        padding: 30px 0;
    }
    .image {
        max-width: 215px;
        max-height: 290px;
        margin-right: 30px;
    }
    .paragraph {
        font-size: 16px;
        line-height: 135.5%;
        max-width: 300px;
    }
    .paragraph:nth-of-type(2n) {
        margin-top: 17px;
    }
}

@media (max-width: 550px) {
    .drinks {
        padding: 0 0 30px;
        flex-direction: column;
        align-items: center;
        background-color: #070707;
    }
    .image {
        max-width: 100%;
        max-height: 100%;
        margin: 0;
    }
    .text {
        margin-top: 30px;
    }
    .paragraph {
        font-size: 13px;
        line-height: 129%;
        max-width: 345px;
        text-align: center;
    }
    .paragraph:nth-of-type(2n) {
        margin-top: 50px;
    }
}